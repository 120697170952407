<template>
  <el-card>
    <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="服务器" name="1">
        <ServiceConfig />
    </el-tab-pane>
    <el-tab-pane label="oss" name="2">
      <el-card>
        <OssConfig />
      </el-card>
    </el-tab-pane>
  </el-tabs>
  </el-card>
</template>

<script>
import ServiceConfig from "./components/serviceConfig.vue";
import OssConfig from "./components/ossConfig.vue";
export default {
  name: "service",
  components:{
    ServiceConfig,
    OssConfig
  },
  data() {
    return {
      activeName:'1',
      form:{},
    };
  },
  mounted() {
   
  },
  methods: {
    handleClick(){}
  },
};
</script>

<style scoped>
</style>
