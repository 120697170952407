<template>
  <div class="main-content">
    <div class="table-content">
      <avue-crud
        :data="data"
        :option="tableColumn"
        :search.sync="search"
        :page.sync="pageSync"
        ref="crud"
      >
        <template slot="menuLeft">
          <div class="marginBottom20">
            <el-button type="primary" size="mini" @click.stop="add()"
              >新增</el-button
            >
          </div>
        </template>

        <template slot="menu" slot-scope="{ row }">
          <el-button type="text"  icon="el-icon-edit" size="small" @click.stop="edit(row)"
            >编辑</el-button
          >
          <!-- <el-button type="text"
                             size="small"
                             @click.stop="clickMenu('authorize',row.id)">授权</el-button> -->
          <el-button
            type="text"
            style="margin-left: 10px"
            size="small"
            icon="el-icon-delete"
            @click.stop="del(row.id)"
            >删除</el-button
          >
        </template>
      </avue-crud>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <avue-form
        :option="option"
        v-model="form"
        ref="form"
        :upload-error="uploadError"
        :upload-delete="uploadDelete"
        :upload-before="uploadBefore"
        :upload-after="uploadAfter"
        @submit="formSubmit"
      >
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
          <el-button type="primary" icon="el-icon-check" @click="submit(form)"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  serverConfigList,
  serverConfigAdd,
  serverConfigView,
  serverConfigUpdate,
  serverConfigDelete,
} from "@/api/system/config";
import { mobilePhoneRule } from "@/utils/formRules";
import roleIndex from "@/components/system/role/index";
import rolePower from "@/components/system/role/power";
export default {
  name: "list",
  components: {
    roleIndex,
    rolePower,
  },
  data() {
    return {
      pageSync: { 
        total: 0,
        currentPage: 1,
        layout: "total, prev, pager, next",
        pageSize:100
      },
      search: {},
      form:{},
      data: [],
      dialogVisible: false,
      tableColumn: {
        titleSize: "h3",
        card: false,
        border: false,
        stripe: false,
        showHeader: true,
        align: "center",
        menuAlign: "center",
        columnBtn: false, //列显隐
        refreshBtn: false, //刷新
        saveBtn: false,
        updateBtn: false,
        cancelBtn: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        menuWidth: 300,
        column: [
        {
            label: "序号",
            prop: "tabelIndex",
          },
         {
            label: "三方厂商名称",
            prop: "serverName",
          },
          {
            label: "产品名称",
            prop: "name",
          },
          {
            label: "账号",
            prop: "secretKey", 
          },
          {
            label: "密码",
            prop: "secretValue",
          },
          {
            label: "管理员",
            prop: "administrators",
          },
          {
            label: "管理员手机",
            prop: "administratorsPhone",
          },
        ],
      },

      option: {
        labelWidth: "140",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "管理员",
            prop: "administrators",
            maxlength: 20,
            span:20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入管理员",
                trigger: "blur",
              },
            ],
          },
          {
            label: "管理员手机",
            prop: "administratorsPhone",
            span:20,
            maxlength: 11,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入管理员手机",
                trigger: "blur",
              },
              ...mobilePhoneRule
            ],
          },
          {
            label: "服务器名称",
            prop: "name",
            maxlength: 20,
            span:20,
            // showWordLimit: true,
            row: true,
            disabled:false,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入服务器名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "账号",
            prop: "secretKey",
            span:20,
            disabled:false,
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入账号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "密码",
            prop: "secretValue",
            span:20,
            maxlength: 20,
            // showWordLimit: true,
            disabled:false,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入密码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "服务器厂商名称",
            span:20,
            prop: "serverName",
            maxlength: 20,
            disabled:false,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入服务器厂商名称",
                trigger: "blur",
              },
            ],
          }
        ],
      },

      title: "",
      choiceId: "",
      dialogVisible: false,
      dialogVisiblePower: false,
      ids: [],
      name: "", // 请求false

      // 角色权限管理
      tabs: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    uploadDelete() {
      // return this.$confirm(`是否确定移除？`);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column);
    },
    uploadAfter(res, done) {
      done();
      // this.$message.success('上传后的方法')'
      // console.log(res);
      // this.form.image = res.image;
      // this.$nextTick(() => {
      //   this.form.image = res.path;
      // });
    },
    formSubmit() {},
    getList() {
      serverConfigList({
        pageNum: this.pageSync.currentPage,
        pageSize: this.pageSync.pageSize,
      })
        .then((res) => {
          this.data = res.data.records;
          this.data.map((item,index)=>{
            item.tabelIndex = index+1
          })
          this.pageSync.total = res.data.total;
          this.pageSync.currentPage = res.data.current;
          // if(res.data.current > res.data.pages){
          //     this.pageSync.currentPage = res.data.pages;
          //     this.role_list();
          // }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
        // 编辑
    edit(row) {
      this.title='编辑'
      this.option.column[2].disabled = true
      this.option.column[3].disabled = true
      this.option.column[4].disabled = true
      this.option.column[5].disabled = true
      this.form = JSON.parse(JSON.stringify(row));
      this.dialogVisible = true;
    },
    add() {
      this.title='新增'
      this.option.column[2].disabled = false
      this.option.column[3].disabled = false
      this.option.column[4].disabled = false
      this.option.column[5].disabled = false
      this.dialogVisible = true;
      this.form.id=''
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          let that = this;

          if (this.form.id) {
            serverConfigUpdate(that.form).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success("修改成功");
                that.getList();
                that.handleClose();
                // done();
              } else {
                that.$message.error(res.msg);
                // done();
              }
            });
          } else {
            serverConfigAdd(that.form).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                that.$message.success(res.msg);
                that.getList();
                that.handleClose();
                // done();
              } else {
                that.$message.error(res.msg);
                // done();
              }
            });
          }
          this.form.type = 1;
          // done()
        }
      });
    },
    del(id) {
      this.$confirm(`删除这条数据`, "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            serverConfigDelete(id)
              .then((res) => {
                // 获取数据成功后的其他操作
                if (res.code === 200) {
                  this.$message.success(res.msg);
                  this.getList();
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          }
        },
      });
    },
  },
};
</script>

<style scoped>
</style>
