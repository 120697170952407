<template>
  <div>
    <avue-form
      :option="option"
      v-model="form"
      ref="form"
      :upload-error="uploadError"
      :upload-delete="uploadDelete"
      :upload-before="uploadBefore"
      :upload-after="uploadAfter"
    >
      <template slot="headPortrait">
        <FormImgUpload
          :url="form.headPortrait"
          @upload="upload"
          :limit="1"
          :disabled="true"
          :maxSize="5"
          accept="image/jpeg,image/png,image/jpg"
          :multiple="true"
        />
      </template>
      <template slot="menuForm">
        <div style="text-align: left">
          <el-button
            icon="el-icon-close"
            style="margin-left: 115px"
            @click="handleClose"
            >取消</el-button
          >
          <el-button type="primary" icon="el-icon-check" @click="submit(form)"
            >确定</el-button
          >
        </div>
      </template>
    </avue-form>
  </div>
</template>

<script>
import {
  ossConfigView,
  ossConfigsaveOrUpdate,
} from "@/api/system/config";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { mobilePhoneRule } from "@/utils/formRules";
export default {
  name: "parameter",
  components: {
    FormImgUpload,
  },
  data() {
    return {
      activeName: "1",
      form: {
        headPortrait: [],
      },
      option: {
        labelWidth: "120",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "accessKey",
            prop: "accessKey",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            disabled: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入accessKey",
                trigger: "blur",
              },
            ],
          },
          {
            label: "accessSecret",
            prop: "accessSecret",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            disabled: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入accessSecret",
                trigger: "blur",
              },
            ],
          },
          {
            label: "bucket",
            prop: "bucket",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            disabled: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入bucket",
                trigger: "blur",
              },
            ],
          },
          {
            label: "endpoint",
            prop: "endpoint",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            disabled: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入endpoint",
                trigger: "blur",
              },
            ],
          },
          {
            label: "regionId",
            prop: "regionId",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            disabled: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入regionId",
                trigger: "blur",
              },
            ],
          },
          {
            label: "serverName",
            prop: "serverName",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            disabled: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入serverName",
                trigger: "blur",
              },
            ],
          },
          {
            label: "stsEndpoint",
            prop: "stsEndpoint",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            disabled: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入stsEndpoint",
                trigger: "blur",
              },
            ],
          },

          {
            label: "管理员",
            prop: "administrators",
            maxlength: 20,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入管理员",
                trigger: "blur",
              },
            ],
          },
          {
            label: "手机",
            prop: "administratorsPhone",
            maxlength: 11,
            // showWordLimit: true,
            row: true,
            placeholder: "请输入",
            rules: [
              {
                required: true,
                message: "请输入手机",
                trigger: "blur",
              },
              ...mobilePhoneRule
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    uploadDelete() {
      // return this.$confirm(`是否确定移除？`);
    },
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
      // console.log(error, column);
    },
    uploadAfter(res, done) {
      done();
      // this.$message.success('上传后的方法')'
      // console.log(res);
      // this.form.image = res.image;
      // this.$nextTick(() => {
      //   this.form.image = res.path;
      // });
    },
    // 列表
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.form.headPortrait = [...e];
      } else {
        this.form.headPortrait = [...this.form.headPortrait, e];
      }
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form.image = [];
      this.form.isShow = 0;
      this.dialogVisible = false;
    },
    getList() {
      ossConfigView(this.tableFrom).then((res) => {
        this.form = res.data;
        if (res.data.logo) {
          this.form.headPortrait = [res.data.logo];
        }
      });
    },
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          let that = this;
          ossConfigsaveOrUpdate({
            ...this.form,
          })
            .then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success("修改成功");
                this.getList();
                // done();
              } else {
                that.$message.error(res.msg);
                // done();
              }
            })
            .catch((err) => {
              that.$message.error(err.msg);
              // done();
            });
          this.form.type = 1;
          // done()
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
